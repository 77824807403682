<template>
    <component :is="templateContent"></component>
</template>

<script>
    import Vue from 'vue'
    import { mapState } from "vuex"
    import Sass from 'sass.js'
    import mixinFunctions from '@/mixin/mixinFunctions'
    export default {
        name: 'Template',
        mixins: [ mixinFunctions ],
        props: {
            obj: {
                type: Object
            }
        },
        data() {
            return {
                //Desktop
                scriptContentDesktop: '',
                styleContentDesktop: '',
                styleCompiledDesktop: '',
                cleanedTemplateDesktop: '',
                //Mobile
                scriptContentMobile: '',
                styleContentMobile: '',
                styleCompiledMobile: '',
                cleanedTemplateMobile: ''
            }
        },
        computed: {
            ...mapState({
                isMobile: 'isMobile'
            }),
            templateContent() {
                var templateHtml = this.isMobile ? this.cleanedTemplateMobile : this.cleanedTemplateDesktop
                var scriptContent = this.isMobile ? this.scriptContentMobile : this.scriptContentDesktop
                // Remove <template> tags
                templateHtml = templateHtml.replace('<template>', '');
                templateHtml = templateHtml.replace('</template>', '');
                // Remove `export default` and the surrounding braces
                scriptContent = scriptContent.replace('export default', '').trim()
                if (scriptContent && scriptContent.startsWith('{') && scriptContent.endsWith('}')) {
                    scriptContent = scriptContent.slice(1, -1).trim()
                }
                var templateObj = this.obj
                var templateMixin = {
                    data() {
                        return {
                            obj: templateObj //This will pass this.obj data to the template component
                        }
                    }
                }
                if(templateHtml == ''){
                    console.error("LhTemplate Error! Empty template html code.")
                    console.log("Is Mobile:", this.isMobile)
                    console.log("HTML Code:", templateHtml)
                } else {


                    var templateComponent = {
                        mixins: [templateMixin],
                        template: templateHtml, // Add the template HTML string directly
                        ...scriptContent && JSON.parse(scriptContent) // Convert the script content to an object if it exists
                    };

                    var styles = this.isMobile ? this.styleContentMobile : this.styleContentDesktop
                    if(styles && styles != '') {
                        this.compileSassToCss(styles)
                            .then(css => {
                                this.styleCompiled = css
                                this.injectCss(css, this._uid)
                            })
                            .catch(error => {
                                console.error('Error compiling SCSS:', error)
                            })
                    }
                    return Vue.extend(templateComponent)
                }
            }
        },
        mounted() {
            this.setupEventListeners();
        },
        methods: {
            setupEventListeners() {
                if(this.obj.actions) {
                    this.obj.actions.forEach((action) => {
                        let targetElement = this.$el.querySelector(action.target);
                        if (targetElement) {
                            targetElement.addEventListener(action.event, this.allEventHandler(action));
                        }
                    });
                }
            },
            compileSassToCss(scssContent){
                return new Promise((resolve, reject) => {
                    Sass.compile(scssContent, result => {
                        if (result.status === 0) {
                            resolve(result.text)
                        } else {
                            reject(result.formatted)
                        }
                    })
                })
            },
            injectCss(cssContent, uid) {
                const styleElement = document.createElement('style')
                styleElement.type = 'text/css'
                styleElement.setAttribute('data-vue-component-id', uid);
                styleElement.appendChild(document.createTextNode(cssContent))
                document.head.appendChild(styleElement)
            }
        },
        created(){
            let templateDesktop = (this.obj.attributes?.templateDesktop) ? this.obj.attributes.templateDesktop :  ''
            let templateMobile = (this.obj.attributes?.templateMobile) ? this.obj.attributes.templateMobile :  ''

            // Variables
            let scriptRegex;
            let styleRegex;
            let scriptMatch;
            let scriptContent;
            let styleMatch;
            let styleContent;
            let cleanedTemplate;

            /* ## Setup Desktop ############## */
            if(templateDesktop && templateDesktop != '') {
                // console.log("templateDesktop", templateDesktop)
                scriptRegex = /<script[^>]*>([\s\S]*?)<\/script>/i
                styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/i
                scriptMatch = templateDesktop.match(scriptRegex)
                scriptContent = scriptMatch ? scriptMatch[1] : ''
                styleMatch = templateDesktop.match(styleRegex)
                styleContent = styleMatch ? styleMatch[1] : ''
                cleanedTemplate = templateDesktop
                    .replace(scriptRegex, '')
                    .replace(styleRegex, '')
                this.scriptContentDesktop = scriptContent
                this.styleContentDesktop = styleContent
                this.cleanedTemplateDesktop = cleanedTemplate
            }

            /* ## Setup Mobile ############## */
            if(templateMobile && templateMobile != '') {
                // console.log("templateMobile", templateMobile)
                scriptRegex = /<script[^>]*>([\s\S]*?)<\/script>/i
                styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/i
                scriptMatch = templateMobile.match(scriptRegex)
                scriptContent = scriptMatch ? scriptMatch[1] : ''
                styleMatch = templateMobile.match(styleRegex)
                styleContent = styleMatch ? styleMatch[1] : ''
                cleanedTemplate = templateMobile
                    .replace(scriptRegex, '')
                    .replace(styleRegex, '')
                this.scriptContentMobile = scriptContent
                this.styleContentMobile = styleContent
                this.cleanedTemplateMobile = cleanedTemplate
            }
        }
    }
</script>

<style>
    /* Add component-specific styles here */
</style>
