<template>
    <div v-if="this.obj.attributes.type" :class="['card', obj.attributes.type, checkClasses]"
         v-on="allEvents" :style="checkStyles" v-tooltip="checkTooltip">
        <!--    title-->

        <!--    content-->
        <div class="widget-content">
            <div v-if="obj.attributes.type === 'type1'" class="widget-stat-container">
                <div class="icon-wrapper">
                    <font-awesome-icon v-if="checkEmpty(obj.attributes.icon)"
                                       :icon="obj.attributes.icon"
                                       class="widget-icon"
                    ></font-awesome-icon>
                    <div v-if="checkEmpty(obj.attributes.title)">
                        <p><b>{{ obj.attributes.titleBefore }}{{ $t(obj.attributes.title) }}{{
                                obj.attributes.titleAfter
                            }}</b>
                            <lh-edit-translation
                                :obj="{key: obj.attributes.title, parentKey: obj.key}"></lh-edit-translation>
                        </p>
                    </div>
                </div>

                <div class="ml-lg-3">
                    <span class="widget-stat"><b> {{ obj.attributes.stat }} </b></span>
                    <font-awesome-icon v-if="checkEmpty(obj.attributes.icon2)"
                                       :icon="obj.attributes.icon2" class="widget-stat"></font-awesome-icon>
                    <component v-for="child in buttonComponents"
                               :is="child.type"
                               :key="child.key"
                               :obj="child"
                               :componentClass="typeClass">
                    </component>
                </div>
            </div>
            <div v-else-if="obj.attributes.type == 'type2'" class="widget-stat-container">
                <div v-if="checkEmpty(obj.attributes.title)">
                    <p class="widget-title">{{ obj.attributes.titleBefore }}{{
                            $t(obj.attributes.title)
                        }}{{ obj.attributes.titleAfter }}
                        <lh-edit-translation
                            :obj="{key: obj.attributes.title, parentKey: obj.key}"></lh-edit-translation>
                    </p>
                    <span class="widget-stat">{{ obj.attributes.stat }}</span>
                </div>
                <div class="icon-wrapper">
                    <font-awesome-icon v-if="checkEmpty(obj.attributes.icon)"
                                       :icon="obj.attributes.icon"
                                       class="widget-icon"
                    ></font-awesome-icon>
                </div>
            </div>
            <div v-else-if="obj.attributes.type == 'multi'" class="widget-stat-container">
                <div class="row stats">
                    <div v-for="stat in obj.attributes.stats"
                         v-if="checkEmpty(stat.title)"
                         :class="['col-sm-'+Math.round(12/obj.attributes.stats.length), 'col-xs-12']">
                        <p class="widget-title">{{ stat.titleBefore }}{{ $t(stat.title) }}{{ stat.titleAfter }}
                            <lh-edit-translation :obj="{key: stat.title, parentKey: obj.key}"></lh-edit-translation>
                        </p>
                        <span class="widget-stat" :class="stat.class" :style="stat.styles">{{ stat.stat }}</span>
                    </div>
                </div>
                <div class="icon-wrapper">
                    <font-awesome-icon v-if="checkEmpty(obj.attributes.icon)"
                                       :icon="obj.attributes.icon"
                                       class="widget-icon"
                    ></font-awesome-icon>
                </div>
            </div>
            <div v-else-if="obj.attributes.type == 'solid-icon-top'" class="widget-stat-container">
                <div class="icon-wrapper" v-if="checkEmpty(obj.attributes.icon)">
                    <font-awesome-icon :icon="obj.attributes.icon" class="widget-icon"></font-awesome-icon>
                </div>
                <div v-for="stat in obj.attributes.stats"
                     v-if="checkEmpty(stat.title)"
                     :class="['col-sm-'+Math.round(12/obj.attributes.stats.length), 'col-xs-12']">
                    <div v-if="checkEmpty(stat.title)">
                        <p class="widget-title">{{ stat.titleBefore }}{{ $t(stat.title) }}{{ stat.titleAfter }}
                            <lh-edit-translation :obj="{key: stat.title, parentKey: obj.key}"></lh-edit-translation>
                        </p>
                        <div class="widget-stat" v-for="sub in stat.data">
                            <span :class="sub.class">{{ sub.value }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="obj.attributes.type == 'icon-tile'" class="widget-stat-container">
                <div class="icon-wrapper" v-if="checkEmpty(obj.attributes.icon)">
                    <font-awesome-icon :icon="obj.attributes.icon" class="widget-icon"></font-awesome-icon>
                </div>
                <div v-if="hasIconPositionClass" class="icon-label-wrapper">
                    <div v-if="obj.attributes.label" class="icon-label">
                        <strong>{{ obj.attributes.label }}</strong>
                    </div>
                    <div v-if="obj.attributes.content" class="icon-content" v-html="obj.attributes.content">
                        {{ obj.attributes.content }}
                    </div>
                </div>
                <div class="icon-label-wrapper">
                    <div v-if="obj.attributes.label" class="icon-label">
                        <strong>{{ obj.attributes.label }}</strong>
                    </div>
                    <div v-if="obj.attributes.content" class="icon-content" v-html="obj.attributes.content">
                        {{ obj.attributes.content }}
                    </div>
                </div>
            </div>
            <div v-if="contentComponents.length > 0">
                <component v-for="child in contentComponents" :is="child.type" :key="child.key"
                           :obj="child"></component>
            </div>
        </div>

        <!--    button-->
        <div v-if="buttonComponents.length > 0 && obj.attributes.type !== 'type1'" class="widget-button">
            <component v-for="child in buttonComponents"
                       :is="child.type"
                       :key="child.key"
                       :obj="child"
                       :componentClass="typeClass">
            </component>
        </div>
    </div>
    <div v-else>
        <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
    </div>
</template>

<script>
import mixinFunctions from '@/mixin/mixinFunctions'
import confirm from '@/mixin/confirm'

export default {
    name: 'LhWidget',
    mixins: [mixinFunctions, confirm],
    components: {},
    props: {
        obj: {
            type: Object
        }
    },
    computed: {
        buttonComponents() {
            return this.obj.children.filter(child => {
                return child.type === 'LhButton' || child.type === 'lh-button'
            })
        },
        contentComponents() {
            return this.obj.children.filter(child => {
                return child.type !== 'LhButton' && child.type !== 'lh-button'
            })
        },
        typeClass() {
            let className = null
            if (this.obj.attributes.type === 'type1') {
                className = 'tertiary'
            }
            return className
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.card {
    color: $widget-color;
    background: $widget-bg;
    text-align: left;
    padding: 1rem;
    width: 100%;
    height: 100%;
    word-break: break-all;
    border: none;
    padding-right: 36px;
    max-width: 320px;

    @include media-breakpoint-up(md) {
        min-width: 320px;
    }

    &.percent {
        color: $medium-blue
    }

    &.multi,
    &.type2,
    &.solid-icon-top,
    &.icon-tile {
        color: $medium-grey;
        background: none;
        border: 1px solid $medium-grey;

        &.type2__align-left {
            @include media-breakpoint-up(lg) {
                .widget-stat-container {
                    > div:first-of-type {
                        text-align: left;
                    }

                    .icon-wrapper {
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &.solid-icon-top {
        padding: 1.5rem;
        border: 1px solid $light-grey;
        border-radius: $border-radius-2;
        min-height: 110px;

        .widget-content {
            .widget-stat-container {
                justify-content: start;

                div[class^="col-"] {
                    padding: 0;
                    width: auto;
                    flex-grow: 1;
                    text-align: left;
                    display: inline-flex;
                    flex: 0 0 auto;

                    & > div {
                        text-align: left;
                    }
                }

                .widget-title {
                    color: $dark-grey;
                    font-weight: normal;
                    font-size: $h5-font-size;
                    padding-right: 44px;
                }

                .widget-stat {
                    font-size: $h6-font-size;
                    margin: 0 0 .25rem 0;

                    &:nth-child(2) {
                        color: $medium-grey;
                        font-weight: bold;
                        font-size: $h3-font-size;
                        margin: 0 0 .5rem 0;
                    }
                }

                .icon-wrapper {
                    display: inline-flex;
                    min-width: 50px;
                    max-width: 50px;
                    min-height: 50px;
                    max-height: 50px;
                    background: linear-gradient(180deg, $dashboard-widget-color 0%, $dashboard-widget-color-light 100%);
                    border-radius: $border-radius-2;
                    margin-right: 1rem;

                    svg {
                        margin: 0;
                        color: $white;
                    }
                }
            }
        }

        &.button-hover {
            transition: all 200ms;

            * {
                transition: all 200ms;
            }

            &:hover {
                cursor: pointer;
                background: linear-gradient(180deg, $primary-color 0%, $primary-color-light 100%);
                border-color: transparent;
                color: $white;

                .widget-content {
                    .widget-stat-container {
                        .icon-wrapper {
                            background: linear-gradient(180deg, $secondary-color 0%, $secondary-color-light 100%);
                        }

                        .widget-title {
                            color: $white;
                        }

                        .widget-stat {
                            &:first-child,
                            &:nth-child(2) {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.icon-tile {
        padding: 1.5rem;
        border: 1px solid $light-grey;
        border-radius: $border-radius-2;
        min-height: 110px;

        &:hover {
            cursor: pointer;
            border-color: darken($light-grey, 10%);
        }

        .widget-content {
            .widget-stat-container {
                justify-content: center;
                flex-direction: column;

                .icon-wrapper {
                    height: auto;

                    svg {
                        margin: 0;
                        font-size: 2em;
                    }
                }

                .icon-label-wrapper {
                    text-align: center;

                    .icon-label {
                        margin: 1rem 0 0 0;
                    }

                    .icon-content {
                        margin: .5rem 0 0 0;
                    }
                }
            }
        }

        &.color-fill {
            background: linear-gradient(-45deg, $primary-color, $secondary-color);
            border: none;
            color: $popup-header-color;

            &:hover {
                background: linear-gradient(-45deg, darken($primary-color, 10%), darken($secondary-color, 10%));
            }

            .widget-content {
                .widget-stat-container {
                    .icon-wrapper {
                        svg {
                            color: $popup-header-color;
                        }
                    }

                    .icon-label {
                        color: $popup-header-color;
                    }
                }
            }
        }

        &.primary-fill {
            background: linear-gradient(-45deg, $primary-color, $primary-color-light);
            border: none;
            color: $white;

            &:hover {
                background: linear-gradient(-45deg, darken($primary-color, 10%), darken($primary-color-light, 10%));
            }

            .widget-content {
                .widget-stat-container {
                    .icon-wrapper {
                        svg {
                            color: $white;
                        }
                    }

                    .icon-label {
                        color: $white;
                    }
                }
            }
        }

        &.secondary-fill {
            background: linear-gradient(-45deg, $secondary-color, $secondary-color-light);
            border: none;
            color: $white;

            &:hover {
                background: linear-gradient(-45deg, darken($secondary-color, 10%), darken($secondary-color-light, 10%));
            }

            .widget-content {
                .widget-stat-container {
                    .icon-wrapper {
                        svg {
                            color: $white;
                        }
                    }

                    .icon-label {
                        color: $white;
                    }
                }
            }
        }

        &.icon-left {
            .widget-content {
                .widget-stat-container {
                    flex-direction: row;
                    justify-content: start;
                    align-items: center;

                    .icon-wrapper {
                        margin-right: 1rem;
                    }

                    .icon-label-wrapper {
                        text-align: left;

                        .icon-label {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &.icon-right {
            .widget-content {
                .widget-stat-container {
                    flex-direction: row-reverse;
                    justify-content: start;
                    align-items: center;

                    .icon-wrapper {
                        margin-left: auto;
                    }

                    .icon-label-wrapper {
                        text-align: left;
                        margin-right: 1rem;

                        .icon-label {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

.widget-content {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    @include media-breakpoint-up(md) {
        padding: 0.5rem 1rem;
    }
}

.widget-stat-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    font-size: 1em;
    line-height: 1;
    width: 100%;

    .row.stats {
        width: 100%;
    }


    @include media-breakpoint-up(sm) {
        flex-flow: column nowrap;
        > div:first-of-type {
            text-align: center;
            margin-bottom: 0.75rem;
        }
    }

    @include media-breakpoint-up(md) {
        font-size: 1.25em;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;

        > div:first-of-type {
            //text-align: initial;
            margin-bottom: 0;
        }
    }
}

.icon-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    word-break: initial;
    text-align: center;
    height: 100%;

    svg {
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1em;
    }

    @include media-breakpoint-up(md) {
        width: 150px;

        p {
            margin-bottom: 5px;
        }
    }
}

.widget-icon {
    font-size: 2.25em;
    margin-bottom: 0.25rem;

    @include media-breakpoint-up(md) {
        font-size: 3em;
    }
}

.widget-stat {
    font-size: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: 2.5em;
    }
}

.multi,
.type2,
.solid-icon-top,
.icon-tile {
    &.card {
        width: 100%;
    }

    .widget-title {
        font-size: 17px;
        font-weight: bold;
        word-break: break-word;
    }

    .widget-content {
        padding: 0;
    }

    p {
        margin-bottom: 0.25em;
    }

    .widget-stat {
        font-size: 16px;
    }

    .widget-icon {
        font-size: 1.5em;
    }

    .icon-wrapper {
        width: 50px;
    }

    .stats {
        & > div {
            margin: 0 0 1rem 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.widget-button {

}
</style>
