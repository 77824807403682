<template>
  <div>
    <component
        v-if="notEmpty(testJSON)"
        :is="testJSON.type"
        :key="testJSON.key"
        :obj="testJSON"
    ></component>
  </div>
</template>

<script>

export default {
  data () {
    return {
      testJSON: {
        'key': 'Root',
        'type': 'LhRoot',
        'actions': [],
        'attributes':
            {
              'version':
                  '0.0.1',
              'pageTitle': 'About dev',
              'backgroundUrl': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse.svg'
            }
        ,
        'styles':
            [], 'children':
            [{
              'key': 'Root_Sidebar',
              'type': 'LhSidebar',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [{
                'key': 'root-menu-logo-client',
                'type': 'LhImage',
                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                'attributes': {
                  'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                  'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                  'class': 'client-logo'
                },
                'styles': { 'cursor': 'pointer' },
                'children': []
              },
                {
                'key': 'root-menu-scroll-content',
                'type': 'LhSidebarScrollContent',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-nav-list',
                  'type': 'LhNavList',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-nav-list-item-1',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_INDEX', 'action': '/', 'icon': 'home' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-2',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CLIENTS', 'action': '/clients', 'icon': 'file-alt' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-3',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PROJECTS', 'action': '/projects', 'icon': 'chart-pie' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-4',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PAGES', 'action': '/pages', 'icon': 'users' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-5',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CONFIG', 'action': '/users', 'icon': 'cog' },
                    'styles': [],
                    'children': [{
                      'key': 'root-menu-nav-sub-list-5',
                      'type': 'LhNavList',
                      'actions': [],
                      'attributes': [],
                      'styles': [],
                      'children': [{
                        'key': 'root-menu-nav-sub-list-5-item-1',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users' },
                        'styles': [],
                        'children': []
                      }, {
                        'key': 'root-menu-nav-sub-list-5-item-2',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_BLOCKS', 'action': '/pageblocks' },
                        'styles': [],
                        'children': []
                      }]
                    }]
                  }]
                }]
              },
                {
                'key': 'root-menu-logo-group',
                'type': 'LhDiv',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-logo-lighthouse',
                  'type': 'LhImage',
                  'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                  'attributes': {
                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                    'class': 'lighthouse-logo'
                  },
                  'styles': { 'cursor': 'pointer' },
                  'children': []
                }]
              }, {
                'key': 'root-menu-button-group',
                'type': 'LhButtonGroup',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-col-1',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-user-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                    'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                    'styles': [],
                    'children': []
                  }]
                }, {
                  'key': 'root-menu-col-2',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [
                      {
                    'key': 'root-menu-logout-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                    'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                    'styles': [],
                    'children': []
                  }
                  ]
                },
                  {
                    'key': 'root-menu-col-3',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'toggle-translations-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: 'translations-toggle-view',
                            type: 'emit',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'toggle-translations-button',
                          icon: 'globe',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  },
                  {
                    'key': 'root-menu-col-4',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'translations-popup-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: '',
                            type: '',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'translations-popup-button',
                          icon: 'file-alt',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  }
                  ]
              }]
            },
              {
                "key":"main-admin-dashboard",
                "type":"LhMainPage",
                "actions":[

                ],
                "attributes":[

                ],
                "styles":[

                ],
                "children": [
                  {
                    "key": "patient-template",
                    "type": "LhTemplate",
                    "actions": [],
                    "attributes": {
                      "data": {
                        "patients": [
                          {
                            "guid":  123,
                            "name": "John TEST",
                            "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non fermentum leo. Ut lacinia ipsum non nunc tincidunt rutrum. Etiam eget volutpat risus. Aenean scelerisque id nisi at laoreet."
                          },
                          {
                            "guid":  321,
                            "name": "Jane TEST",
                            "info": "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean."
                          },
                        ]
                      },
                      "templateDesktop": "<template>\r\n<div class=\"row\"><div v-for=\"(patient, index) in obj.attributes.data.patients\" class=\"col-md-6 mx-auto mt-5\">\r\n<div class=\"card\">\r\n<div class=\"row\"><div class=\"col-4\"><img src=\"https:\/\/via.placeholder.com\/500\" class=\"card-img-top\" alt=\"Placeholder Image\"></div><div class=\"col-8\"><div class=\"card-body\">\r\n<h5 class=\"card-title\">{{ patient.name }}<\/h5>\r\n<p class=\"card-text\">{{ patient.info }}<\/p><br />\r\n<a :href=\"\'\/patients\/detail\/\' + patient.guid\" class=\"btn btn-primary\">Details<\/a>\r\n<\/div></div>\r\n<\/div>\r\n<\/div>\r\n<\/div><\/div>\r\n<\/template>",
                      "templateMobile": "<template>\r\n<div class=\"row\"><div v-for=\"(patient, index) in obj.attributes.data.patients\" class=\"col-md-12 mx-auto mt-5\">\r\n<div class=\"card\">\r\n<img src=\"https:\/\/via.placeholder.com\/150\" class=\"card-img-top\" alt=\"Placeholder Image\">\r\n<div class=\"card-body\">\r\n<h5 class=\"card-title\">{{ patient.name }}<\/h5>\r\n<p class=\"card-text\">{{ patient.info }}<\/p><br />\r\n<a :href=\"\'\/patients\/detail\/\' + patient.guid\" class=\"btn btn-primary\">Details<\/a>\r\n<\/div>\r\n<\/div>\r\n<\/div><\/div>\r\n<\/template>"
                    },
                    "styles": [],
                    "children": []
                  },
                  {
                    "key": "patient-template-2",
                    "type": "LhTemplate",
                    "actions": [
                      {
                        "url": "\/users\/detail\/8c501cebc6358bb163d77573496c2b96f953b90debba1bbdb1cae723a5cbb034c9c00db5a80c133e2502f28bc7537f722db8ebde6087fa974cc9f1585c35f195",
                        "type": "update",
                        "method": "GET",
                        "event": "dblclick",
                        "target": "#testPatientClick0"
                      }
                    ],
                    "styles": [],
                    "attributes": {
                      "test": false,
                      "data": {
                        "patients": [
                          {
                            "name": "John TEST"
                          },
                          {
                            "name": "Jane TEST"
                          },
                        ]
                      },
                      "templateDesktop": '<div class="test">\n' +
                          '    <div class="subtest">\n' +
                          '        <p>\n' +
                          '            Testing\n' +
                          '        </p>\n' +
                          '    </div>\n' +
                          '    <div>\n' +
                          '        <p>{{$t(\'GENERAL_NO_RESULTS\')}}</p>\n' +
                          '        <p v-if="obj.attributes.test">TEST A</p>\n' +
                          '        <p v-else>TEST B</p>\n' +
                          '         <div class="patient" v-for="(patient, index) in obj.attributes.data.patients">\n' +
                          '             <p :id="\'testPatientClick\' + index">{{patient.name}}</p>\n' +
                          '         </div>\n' +
                          '    </div>\n' +
                          '</div>\n' +
                          '<script>\n' +
                          '        mounted(){\n' +
                          '            console.log("Template module MOUNTED Desktop", this.obj)\n' +
                          '        }\n' +
                          '<\/script>\n'+
                          '<style scoped lang="scss">\n'+
                          '  div.patient {\n'+
                          '    p { color: red; }\n'+
                          '  }\n'+
                          '<\/style>',
                      "templateMobile": '<div class="test">\n' +
                          '    <div class="subtest">\n' +
                          '        <p>\n' +
                          '            MOBILE Testing\n' +
                          '        </p>\n' +
                          '    </div>\n' +
                          '    <div>\n' +
                          '        <p>THIS IS THE MOBILE HTML & CODE, Adjust as you see fit</p>\n' +
                          '        <p>{{$t(\'GENERAL_NO_RESULTS\')}}</p>\n' +
                          '        <p v-if="obj.test">TEST A</p>\n' +
                          '        <p v-else>TEST B</p>\n' +
                          '         <div class="patient" v-for="patient in patients">\n' +
                          '             <p>{{patient.name}}</p>\n' +
                          '         </div>\n' +
                          '    </div>\n' +
                          '</div>\n' +
                          '<script>\n' +
                          '        data() {\n' +
                          '            return {\n' +
                          '                patients: [\n' +
                          '                    {\n' +
                          '                        name: "John Doe"\n' +
                          '                    },\n' +
                          '                    {\n' +
                          '                        name: "JaneDoe"\n' +
                          '                    },\n' +
                          '                ]\n' +
                          '            }\n' +
                          '        },\n' +
                          '        mounted(){\n' +
                          '            console.log("Template module MOUNTED MOBILE")\n' +
                          '        }\n' +
                          '<\/script>\n'+
                          '<style scoped lang="scss">\n'+
                          '  div.patient {\n'+
                          '    p { color: red; }\n'+
                          '  }\n'+
                          '<\/style>',
                    },
                    "children": []
                  }
                ]
              }
          ]
      },
    }
  }
}
</script>

<style scoped lang="scss">
</style>
